import React from "react";

interface ARModalBodyProps {
  children: React.ReactNode;
  sx?: string;
}

export const ARModalBody: React.FC<ARModalBodyProps> = ({ children, sx }) => {
  return (
    <div
      className={`bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 sm:items-start ${
        sx || ""
      }`}
    >
      {children}
    </div>
  );
};
