import React, { useState, useMemo, useEffect } from "react";
import Navbar from "../../components/Navbars/IndexNavbar";
import { ARCard } from "../../components/CustomComponents/ARCard/ARCard";
import { ARCardBody } from "../../components/CustomComponents/ARCard/ARCardBody";
import ModalViewReportMap from "../../components/Modals/ModalViewReportMap";
import ARIconButton from "../../components/CustomComponents/ARIconButton";
import ModalUserAction from "../../components/Modals/ModalUserAction";
import { ARCardActions } from "../../components/CustomComponents/ARCard/ARCardActions";
import { getData } from "../../apis/Get";
import PrivateRoute from "../../layouts/PrivateRoute";

export interface IUsuario {
  id: number;
  username: string;
  name: string;
  last_name?: string;
  email: string;
  role_id: string;
  phone?: string;
  phone_verify?: string;
  photo?: string;
  created_at?: string;
  updated_at?: string;
}

const usuariosPrueba: IUsuario[] = [
  {
    id: 1,
    username: "NA",
    name: "Jona",
    email: "jona@correo.com",
    last_name: "",
    role_id: "1",
    created_at: "8/09/2024",
    updated_at: "8/09/2024",
  },
  {
    id: 2,
    username: "NA",
    name: "Diego",
    email: "diego@correo.com",
    last_name: "",
    role_id: "2",
    created_at: "8/09/2024",
    updated_at: "8/09/2024",
  },
  {
    id: 3,
    username: "NA",
    name: "Toño",
    last_name: "",
    email: "toño@correo.com",
    role_id: "3",
    created_at: "8/09/2024",
    updated_at: "8/09/2024",
  },
];

const columns = {
  ID: "id",
  Username: "username",
  Nombre: "name",
  "Apellido(s)": "last_name",
  "Correo electrónico": "email",
  Rol: "role_id",
  "Creado en": "created_at",
  "Última Actualización": "updated_at",
  Acción: "action",
};
const Users: React.FC = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const api = urlParams.get("get") || "";
  const [usuarios, setUsuarios] = useState<IUsuario[]>(usuariosPrueba);
  const [usuario, setUsuario] = useState<IUsuario>({
    id: 0,
    username: "",
    name: "",
    last_name: "",
    email: "",
    role_id: "",
    created_at: "",
    updated_at: "",
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [openUserAction, setOpenUserAction] = useState<boolean>(false);
  const [action, setAction] = useState<string>("Editar");
  const [columnFilter, setColumnFilter] = useState<boolean>(false);

  const filteredUsuarios = useMemo(() => {
    return usuarios.filter((usuario) =>
      usuario.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [usuarios, searchTerm]);

  useEffect(() => {
    getData(api, setUsuarios);
  }, []);

  const handleUserAction = (actionType: string, selectedUser: IUsuario) => {
    setAction(actionType);
    setUsuario(selectedUser);
    setOpenUserAction(true);
  };

  return (
    <PrivateRoute>
      <Navbar />
      <div
        className="flex w-full justify-center items-center"
        style={{ height: "90vh" }}
      >
        <div className="w-11/12">
          <ARCard>
            <ARCardActions>
              <h2 className="text-2xl font-semibold leading-tight">Usuarios</h2>
              <div className="flex flex-row items-center">
                <div className="block relative">
                  <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                    <i className="far fa-search" />
                  </span>
                  <input
                    placeholder="Buscar"
                    className="appearance-none rounded-l border border-gray-400 block pl-8 pr-6 py-2 w-60 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <ARIconButton
                  icon="fas fa-filter fa-lg"
                  onClick={() => {
                    setColumnFilter(!columnFilter);
                  }}
                  sx={`${
                    columnFilter ? "text-black-300" : "text-gray-400"
                  } bg-gray-200 hover:bg-gray-300`}
                />
                <ARIconButton
                  icon="fas fa-user-plus"
                  onClick={() => {
                    setAction("Añadir");
                    setUsuario({
                      id: 0,
                      name: "",
                      last_name: "",
                      email: "",
                      role_id: "",
                      username: "",
                      created_at: "",
                      updated_at: "",
                    });
                    setOpenUserAction(true);
                  }}
                  sx={`bg-green-200 hover:bg-green-300`}
                />
              </div>
            </ARCardActions>
            <ARCardBody>
              <div className="container mx-auto px-4 sm:px-8">
                <div className="py-8">
                  <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                    <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
                      <table className="min-w-full leading-normal">
                        <thead>
                          <tr>
                            {Object.keys(columns).map((column) => (
                              <th
                                key={column}
                                className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                              >
                                {column}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        {columnFilter && (
                          <tr>
                            {Object.keys(columns).map((column) => (
                              <th
                                key={column}
                                className="px-1 py-1 border-b-2 border-gray-200 bg-gray-100 text-xs text-gray-600"
                              >
                                <input placeholder={`Filtrar ${column}`} />
                              </th>
                            ))}
                          </tr>
                        )}
                        <tbody>
                          {filteredUsuarios.map((usuario) => (
                            <tr key={usuario.id}>
                              {Object.values(columns).map((data, index) => (
                                <td
                                  key={index}
                                  className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                                >
                                  <p className="text-black-900 whitespace-no-wrap p-1">
                                    {data === "role_id"
                                      ? usuario[data]
                                      : usuario[data as keyof IUsuario] ||
                                        "No Asignado"}
                                  </p>
                                </td>
                              ))}
                              <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <ARIconButton
                                  icon="fas fa-user-edit"
                                  onClick={() =>
                                    handleUserAction("Editar", usuario)
                                  }
                                  sx="bg-blue-300 hover:bg-blue-400"
                                />
                                <ARIconButton
                                  icon="fas fa-user-slash"
                                  onClick={() =>
                                    handleUserAction("Eliminar", usuario)
                                  }
                                  sx="bg-red-300 hover:bg-red-400"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ARCardBody>
          </ARCard>
        </div>
      </div>
      <ModalViewReportMap open={open} setOpen={setOpen} />
      <ModalUserAction
        open={openUserAction}
        setOpen={setOpenUserAction}
        user={usuario}
        action={action}
      />
    </PrivateRoute>
  );
};

export default Users;
