import React, { ReactNode } from "react";

interface ARCardBodyProps {
  children: ReactNode;
  className?: string;
}

export const ARCardBody: React.FC<ARCardBodyProps> = ({
  children,
  className = "",
}) => {
  return <div className={`p-4 ${className}`}>{children}</div>;
};
