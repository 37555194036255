import React, { useState } from "react";
import { IPointOfInterest } from "./PoiReviews";

interface PoiDetailProps {
  poi: IPointOfInterest;
}

const estrellas = (num: number) => "⭐".repeat(num);

const ReviewList: React.FC<{ reviews: any[] }> = ({ reviews }) => (
  <div className="w-full">
    <h3 className="text-lg font-semibold">Reseñas</h3>
    <ul className="divide-y divide-gray-200">
      {reviews.map((review) => (
        <li key={review.id} className="py-2">
          <p>{review.text}</p>
          <p className="text-sm text-gray-500">
            Calificación: {estrellas(review.rating)}
          </p>
        </li>
      ))}
    </ul>
  </div>
);

const ImageCarousel: React.FC<{
  photos: string[];
  currentIndex: number;
  onPrev: () => void;
  onNext: () => void;
}> = ({ photos, currentIndex, onPrev, onNext }) => (
  <div className="flex items-center justify-center mt-4">
    <button className="bg-white p-2 rounded-full shadow-md" onClick={onPrev}>
      &#10094;
    </button>
    <img
      src={photos[currentIndex]}
      alt={`Foto ${currentIndex + 1}`}
      className="h-64 w-auto mx-4 object-cover rounded-lg"
    />
    <button className="bg-white p-2 rounded-full shadow-md" onClick={onNext}>
      &#10095;
    </button>
  </div>
);

const PoiDetail: React.FC<PoiDetailProps> = ({ poi }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const photos = [
    "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
    "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
  ];

  if (!poi) return null;

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="bg-white h-full p-4">
      <div className="mb-4 rounded shadow-lg">
        <img
          src={
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80"
          }
          alt={poi.name}
          className="w-full h-48 object-cover mb-2 rounded"
        />
      </div>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flex-col w-full md:w-1/2 md:pr-4">
          <h2 className="text-xl font-bold mb-2">{poi.name}</h2>
          <p className="text-gray-700 mb-2">{poi.description}</p>
          <p className="text-gray-500 mb-4">
            Ubicación: {poi.latitude}, {poi.longitude}
          </p>
          <ReviewList reviews={poi.reviews} />
        </div>
        <div className="flex-col w-full md:w-1/2 mt-4 md:mt-0">
          <ImageCarousel
            photos={photos}
            currentIndex={currentImageIndex}
            onPrev={handlePrev}
            onNext={handleNext}
          />
        </div>
      </div>
    </div>
  );
};

export default PoiDetail;
