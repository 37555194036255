import { IInput } from "../../interfaces/IInput";
import { SizeTokens } from "../../styling/Size";

export const ARInput = ({
  label = "",
  defaultValue = "",
  value = undefined,
  helperText = "",
  onChange,
  size = "m",
  type = "text",
  sx,
  disabled,
}: IInput) => {
  return (
    <div className={`${sx} ${SizeTokens.Input[size]} px-4`}>
      <div className="relative w-full mb-3">
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="grid-password"
        >
          {label}
        </label>

        <input
          type={type}
          className={`border-0 px-3 py-3 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 ${
            disabled ? "bg-gray-200 text-gray-500 cursor-not-allowed" : ""
          }`}
          defaultValue={defaultValue}
          value={value === "" ? undefined : value}
          onChange={onChange}
          disabled={disabled}
        />

        <small id="emailHelp" className="form-text text-muted">
          {helperText}
        </small>
      </div>
    </div>
  );
};
