import React from "react";
import PropTypes from "prop-types";

interface CardStatsProps {
  statSubtitle: string;
  statTitle: string;
  statArrow: any;
  statPercent: string;
  statPercentColor: string;
  statDescription?: string;
  statIconName: string;
  statIconColor: string;
  onClick: Function; // Añadimos onClick como propiedad
}

const CardStats: React.FC<CardStatsProps> = ({
  statSubtitle,
  statTitle,
  statArrow,
  statPercent,
  statPercentColor,
  statDescription = "",
  statIconName,
  statIconColor,
  onClick,
}) => {
  return (
    <div
      className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg cursor-pointer" // Añadido cursor-pointer para indicar que es clickeable
      onClick={() => onClick()}
    >
      <div className="flex-auto p-4">
        <div className="flex flex-wrap">
          <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
            <h5 className="text-blueGray-400 uppercase font-bold text-xs">
              {statSubtitle}
            </h5>
            <span className="font-semibold text-xl text-blueGray-700">
              {statTitle}
            </span>
          </div>
          <div className="relative w-auto pl-4 flex-initial">
            <div
              className={`text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full ${statIconColor}`}
            >
              <i className={statIconName}></i>
            </div>
          </div>
        </div>
        <p className="text-sm text-blueGray-400 mt-4">
          <span className={`${statPercentColor} mr-2`}>
            <i className={`fas fa-arrow-${statArrow}`} /> {statPercent}%
          </span>
          <span className="whitespace-nowrap">{statDescription}</span>
        </p>
      </div>
    </div>
  );
};

CardStats.propTypes = {
  statSubtitle: PropTypes.string.isRequired,
  statTitle: PropTypes.string.isRequired,
  statArrow: PropTypes.oneOf(["up", "down"]).isRequired,
  statPercent: PropTypes.string.isRequired,
  statPercentColor: PropTypes.string.isRequired,
  statDescription: PropTypes.string,
  statIconName: PropTypes.string.isRequired,
  statIconColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired, // Aseguramos que onClick sea una función requerida
};

export default CardStats;
