import React from "react";

interface ARIconButtonProps {
  icon: string;
  onClick: () => void;
  ariaLabel?: string;
  sx?: string;
}

const ARIconButton: React.FC<ARIconButtonProps> = ({
  icon,
  onClick,
  ariaLabel,
  sx = "",
}) => {
  return (
    <button
      onClick={onClick}
      aria-label={ariaLabel}
      className={`flex items-center justify-center p-3 rounded transition duration-200 ease-in-out ${sx} transform hover:scale-105`}
    >
      <i className={icon}></i>
    </button>
  );
};

export default ARIconButton;
