import { useState } from "react";
import { ARInput } from "../CustomComponents/ARInput";
import { ARTypography } from "../CustomComponents/ARTypography";
import { IUser } from "../../interfaces/user";

export const CardSettings = ({
  newUserInfo,
  setNewUserInfo,
}: {
  newUserInfo: IUser;
  setNewUserInfo: Function;
}) => {
  return (
    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
      <div className="rounded-t bg-white mb-0 px-6 py-6">
        <div className="text-center flex justify-between">
          <ARTypography
            sx="text-blueGray-700 text-xl font-bold"
            value={"Mi Cuenta"}
          />
          <button
            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
          >
            Guardar cambios
          </button>
        </div>
      </div>
      <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
          <ARTypography
            sx="text-blueGray-400 mt-3 mb-6 font-bold uppercase"
            value={"Información del usuario"}
          />
          <div className="flex flex-wrap">
            <ARInput
              label={"Nombre"}
              defaultValue={"lucky.jesse"}
              value={newUserInfo.name}
              onChange={() => {}}
            />
            <ARInput
              label={"Correo Electrónico"}
              defaultValue={"jesse@example.com"}
              value={newUserInfo.email}
              onChange={() => {}}
            />
            <ARInput
              label={"Rol"}
              defaultValue={"Dev"}
              value={newUserInfo.role_id}
              onChange={() => {}}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
