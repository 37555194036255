import axios from "axios";
import { IUsuario } from "../views/admin/Users";

export const createUsuario = async (
  end: string,
  datos: IUsuario,
  set?: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "post",
      url: process.env.REACT_APP_APPLICATION_BACK + end,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: datos,
      },
    });

    set && set(response.data);
  } catch (error) {
    alert(`Error al crear el usuario: ${error}`);
  }
};

export const updateUsuario = async (
  end: string,
  id: number,
  name: string,
  set: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "put",
      url: process.env.REACT_APP_APPLICATION_BACK + end,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id,
        name: name,
      },
    });
    set(response.data);
  } catch (error) {
    alert(`Error al actualizar el usuario: ${error}`);
  }
};

export const deleteUsuario = async (end: string, id: number, set: Function) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "delete",
      url: process.env.REACT_APP_APPLICATION_BACK + end,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id,
      },
    });
    set(response.data);
  } catch (error) {
    alert(`Error al eliminar el usuario: ${error}`);
  }
};
