import React, { useEffect, useMemo, useState } from "react";
import { ARCard } from "../../components/CustomComponents/ARCard/ARCard";
import { ARCardActions } from "../../components/CustomComponents/ARCard/ARCardActions";
import { ARCardBody } from "../../components/CustomComponents/ARCard/ARCardBody";
import ARIconButton from "../../components/CustomComponents/ARIconButton";
import ModalCatalogAction from "../../components/Modals/ModalCatalogAction";
import Navbar from "../../components/Navbars/IndexNavbar";
import { getData } from "../../apis/Get";
import PrivateRoute from "../../layouts/PrivateRoute";

export interface ICatalogo {
  id: number;
  name: string;
  created_at?: string;
  updated_at?: string;
}

const columns = [
  { key: "id", label: "ID" },
  { key: "name", label: "Nombre" },
  { key: "created_at", label: "Fecha de Creación" },
  { key: "updated_at", label: "Última Actualización" },
  { key: "actions", label: "Acción" },
];

const Catalogs: React.FC = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const catalog = urlParams.get("catalog") || "Catálogos";
  const api = urlParams.get("get") || "";

  const [catalogo, setCatalogo] = useState<ICatalogo[]>([]);
  const [selectedCatalogo, setSelectedCatalogo] = useState<
    ICatalogo | undefined
  >(undefined);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openCatalogAction, setOpenCatalogAction] = useState<boolean>(false);
  const [action, setAction] = useState<string>("Editar");
  const [columnFilter, setColumnFilter] = useState<boolean>(false);

  const filteredCatalogos = useMemo(() => {
    return catalogo.filter((catalogo) =>
      catalogo.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [catalogo, searchTerm]);

  const handleOpenModal = (
    actionType: string,
    catalogo: ICatalogo | undefined
  ) => {
    setAction(actionType);
    setSelectedCatalogo(catalogo);
    setOpenCatalogAction(true);
  };

  useEffect(() => {
    getData(api, setCatalogo);
  }, []);

  return (
    <PrivateRoute>
      <Navbar />
      <div
        className="flex w-full justify-center items-center"
        style={{ height: "90vh" }}
      >
        <div className="w-11/12 h-5/6">
          <ARCard className="h-full">
            <ARCardActions>
              <h2 className="text-2xl font-semibold leading-tight">
                {catalog}
              </h2>
              <div className="flex items-center space-x-2">
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <i className="far fa-search" />
                  </span>
                  <input
                    placeholder="Buscar"
                    className="rounded-l border border-gray-400 block pl-8 pr-6 py-2 w-60 bg-white text-sm placeholder-gray-400 text-gray-700 focus:outline-none"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <ARIconButton
                  icon="fas fa-plus"
                  onClick={() => handleOpenModal("Agregar", undefined)}
                  sx="bg-green-200 hover:bg-green-300"
                />
              </div>
            </ARCardActions>
            <ARCardBody className="h-full">
              <div className="container mx-auto px-4 sm:px-8 py-8 h-full">
                <div className="overflow-x-auto h-full">
                  <div className="min-w-full shadow rounded-lg overflow-hidden h-full">
                    <table className="min-w-full leading-normal">
                      <thead>
                        <tr>
                          {columns.map(({ key, label }) => (
                            <th
                              key={key}
                              className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                            >
                              {label}
                            </th>
                          ))}
                        </tr>
                        {/* {columnFilter && (
                          <tr>
                            {columns.map(({ key }) => (
                              <th
                                key={key}
                                className="px-1 py-1 border-b-2 border-gray-200 bg-gray-100 text-xs text-gray-600"
                              >
                                <input placeholder={`Filtrar ${key}`} />
                              </th>
                            ))}
                          </tr>
                        )} */}
                      </thead>
                      <tbody>
                        {filteredCatalogos.map((catalogo) => (
                          <tr key={catalogo.id}>
                            {columns.map(({ key }) => (
                              <td
                                key={key}
                                className="px-5 py-5 border-b border-gray-200 bg-white"
                              >
                                <p className="text-black-900 whitespace-no-wrap">
                                  {key === "actions" ? (
                                    <>
                                      <ARIconButton
                                        icon="fas fa-edit"
                                        onClick={() =>
                                          handleOpenModal("Editar", catalogo)
                                        }
                                        sx="bg-blue-300 hover:bg-blue-400"
                                      />
                                      <ARIconButton
                                        icon="fas fa-trash"
                                        onClick={() =>
                                          handleOpenModal("Eliminar", catalogo)
                                        }
                                        sx="bg-red-300 hover:bg-red-400"
                                      />
                                    </>
                                  ) : (
                                    catalogo[key as keyof ICatalogo] ||
                                    "No Asignado"
                                  )}
                                </p>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ARCardBody>
          </ARCard>
        </div>
      </div>
      <ModalCatalogAction
        open={openCatalogAction}
        setOpen={setOpenCatalogAction}
        elemento={selectedCatalogo}
        action={action}
        api={api}
      />
    </PrivateRoute>
  );
};

export default Catalogs;
