import React, { useEffect, useState } from "react";
import { ARCard } from "../components/CustomComponents/ARCard/ARCard";
import { ARCardBody } from "../components/CustomComponents/ARCard/ARCardBody";
import ModalViewReportMap from "../components/Modals/ModalViewReportMap";
import Navbar from "../components/Navbars/IndexNavbar";
import { getData } from "../apis/Get";
import { ARTypography } from "../components/CustomComponents/ARTypography";
import PrivateRoute from "../layouts/PrivateRoute";

export interface IReport {
  id: number;
  title: string;
  description: string;
  reportType: string;
  status: "Pendiente" | "En Proceso" | "Rechazado";
  priority: number;
  group: string;
  reporter: string;
  created: string;
  latitude: string;
  longitude: string;
}

const initialReports: IReport[] = [
  {
    id: 1,
    title: "Bache 1",
    description: "pozo en calle",
    reportType: "bache",
    status: "Pendiente",
    priority: 1,
    group: "grupo_001",
    reporter: "Jona",
    created: "june 23, 2024",
    latitude: "22'123''123",
    longitude: "-22'123''123",
  },
  {
    id: 2,
    title: "Bache 2",
    description: "pozo en calle",
    reportType: "bache",
    status: "Rechazado",
    priority: 1,
    group: "grupo_002",
    reporter: "Diego",
    created: "june 23, 2024",
    latitude: "45'726''234",
    longitude: "-63'345''732",
  },
  {
    id: 3,
    title: "Bache 3",
    description: "pozo en calle",
    reportType: "bache",
    status: "En Proceso",
    priority: 1,
    group: "grupo_001",
    reporter: "Toño",
    created: "june 23, 2024",
    latitude: "46'733''845",
    longitude: "-72'425''655",
  },
  {
    id: 4,
    title: "Bache 4",
    description: "pozo en calle",
    reportType: "bache",
    status: "Pendiente",
    priority: 1,
    group: "",
    reporter: "Jona",
    created: "june 23, 2024",
    latitude: "22'123''123",
    longitude: "-22'123''123",
  },
  {
    id: 5,
    title: "Bache 5",
    description: "pozo en calle",
    reportType: "bache",
    status: "En Proceso",
    priority: 1,
    group: "",
    reporter: "Diego",
    created: "june 23, 2024",
    latitude: "45'726''234",
    longitude: "-63'345''732",
  },
  {
    id: 6,
    title: "Bache 6",
    description: "pozo en calle",
    reportType: "bache",
    status: "Rechazado",
    priority: 1,
    group: "grupo_001",
    reporter: "Toño",
    created: "june 23, 2024",
    latitude: "46'733''845",
    longitude: "-72'425''655",
  },
];

const columns = [
  "ID",
  "Título",
  "Descripción",
  "Tipo de Reporte",
  "Estatus",
  "Prioridad",
  "Grupo",
  "Reportado por",
  "Fecha del reporte",
  "Latitud",
  "Longitud",
  "Ver",
];

const Reports: React.FC = () => {
  const [reports, setReports] = useState<IReport[]>(initialReports);
  const [selectedReport, setSelectedReport] = useState<IReport | undefined>(
    undefined
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(false);

  const updateReportStatus = (status: "En Proceso" | "Rechazado") => {
    setReports((prevReports) =>
      prevReports.map((report) =>
        report.id === selectedReport?.id ? { ...report, status } : report
      )
    );
  };

  const handleOpenModal = (report: IReport) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  useEffect(() => {
    getData("/reports", setReports);
  }, []);

  return (
    <PrivateRoute>
      <Navbar />
      <div className="flex justify-center items-center w-full min-h-screen px-4">
        <div className="w-full max-w-4xl">
          <ARCard>
            <ARCardBody>
              <h2 className="text-2xl font-semibold leading-tight">Reportes</h2>
              <SearchBar
                isFilterVisible={isFilterVisible}
                setIsFilterVisible={setIsFilterVisible}
              />
              <ReportsTable
                reports={reports}
                onReportClick={handleOpenModal}
                isFilterVisible={isFilterVisible}
              />
            </ARCardBody>
          </ARCard>
        </div>
      </div>
      <ModalViewReportMap
        open={isModalOpen}
        setOpen={setIsModalOpen}
        report={selectedReport}
      />
    </PrivateRoute>
  );
};

const SearchBar: React.FC<{
  isFilterVisible: boolean;
  setIsFilterVisible: (val: boolean) => void;
}> = ({ isFilterVisible, setIsFilterVisible }) => (
  <div className="my-2 flex flex-col sm:flex-row items-center relative">
    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
      <i className="far fa-search"></i>
    </span>
    <input
      placeholder="Buscar"
      className="appearance-none rounded-l border border-gray-400 block pl-8 pr-6 py-2 w-full sm:w-60 bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
    />
  </div>
);

const ReportsTable: React.FC<{
  reports: IReport[];
  onReportClick: (report: IReport) => void;
  isFilterVisible: boolean;
}> = ({ reports, onReportClick, isFilterVisible }) => {
  return (
    <div className="py-4 overflow-x-auto">
      <div className="inline-block shadow rounded-lg overflow-hidden">
        <table className="leading-normal w-full">
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column}
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  {column}
                </th>
              ))}
            </tr>
            {isFilterVisible && (
              <tr>
                {columns.map((column) => (
                  <th
                    key={column}
                    className="px-1 py-1 border-b-2 border-gray-200 bg-gray-100 text-xs text-gray-600"
                  >
                    <input
                      placeholder={`Filtrar ${column}`}
                      className="w-full"
                    />
                  </th>
                ))}
              </tr>
            )}
          </thead>
          <tbody>
            {reports.length > 0 ? (
              reports.map((report) => (
                <tr key={report.id}>
                  {Object.entries(report).map(([key, value]) => (
                    <td
                      key={key}
                      className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                    >
                      <p className={`text-black-900 whitespace-no-wrap p-1 `}>
                        {value || "No Asignado"}
                      </p>
                    </td>
                  ))}
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <a
                      href="#"
                      className="font-medium text-blue-600 hover:underline"
                      onClick={() => onReportClick(report)}
                    >
                      <i className="fas fa-search-location text-lg"></i>
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <ARTypography value={"Sin registros"} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Reports;
