import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import "./assets/styles/tailwind.css";

import { ReportsMap } from "./components/Maps/ReportsMap";
import Navbar from "./components/Navbars/IndexNavbar";
import { PoiReviews } from "./components/POIs/PoiReviews";
import Catalogs from "./views/admin/Catalogs";
import Dashboard from "./views/admin/Dashboard";
import Maps from "./views/admin/Maps";
import Settings from "./views/admin/Settings";
import Users from "./views/admin/Users";
import Login from "./views/auth/Login";
import Landing from "./views/Landing";
import Profile from "./views/Profile";
import Reports from "./views/Reports";
import { PoiCatalogs } from "./components/POIs/PoiCatalogs";
import Index from "./views/Index";

export const links = {
  auth: {
    login: "/auth/login",
    register: "/auth/register",
  },
  admin: {
    dashboard: "/admin/dashboard",
    settings: "/admin/settings",
    users: "/admin/users",
    catalogs: "/admin/catalogs",
    poiCatalogs: "/admin/poi/catalogs",
  },
  index: "/",
  landing: "/landing",
  profile: "/profile",
  reports: "/reports",
  reportsMap: "/reports/map",
  reviews: "/reviews",
  map: "/map",
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={links.landing} element={<Landing />} />
        <Route path={links.map} element={<Maps />} />
        <Route path={links.index} element={<Index />} />
        {/* <Route path={"/admin/dashboard"} element={<Dashboard />} /> */}
        <Route path={links.admin.dashboard} element={<Maps />} />

        <Route path={links.reports} element={<Reports />} />
        <Route path={links.reportsMap} element={<ReportsMap />} />
        <Route path={links.reviews} element={<PoiReviews />} />

        <Route path={links.admin.poiCatalogs} element={<PoiCatalogs />} />
        <Route path={links.admin.settings} element={<Settings />} />
        <Route path={links.admin.users} element={<Users />} />
        <Route path={links.admin.catalogs} element={<Catalogs />} />

        <Route
          path={links.auth.register}
          element={<Navigate to={links.auth.login} />}
        />
        <Route path={links.auth.login} element={<Login />} />

        <Route path={links.profile} element={<Profile />} />

        <Route
          path="*"
          element={
            <>
              <Navbar />
              <div className="max-w-full text-center">
                <strong className="block text-3xl">404 Not Found</strong>
              </div>
            </>
          }
        />
        <Route path={links.index} element={<Index />} />
        <Route path="*" element={<p>404 - No encontrado</p>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
