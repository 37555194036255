import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";
import { ARModalHeader } from "../CustomComponents/ARModal/ARModalHeader";
import { ARTypography } from "../CustomComponents/ARTypography";
import { FC, useState, useEffect } from "react";
import { ICatalogo } from "../../views/admin/Catalogs";
import { ARInput } from "../CustomComponents/ARInput";
import { createRegistro } from "../../apis/Catalogos";

interface ModalCatalogActionProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  elemento?: ICatalogo;
  action?: string;
  api: string;
}

const ModalCatalogAction: FC<ModalCatalogActionProps> = ({
  open,
  setOpen,
  elemento,
  action,
  api,
}) => {
  const [newValue, setNewValue] = useState<ICatalogo>({
    id: 0,
    name: "",
  });

  useEffect(() => {
    if (elemento) {
      setNewValue(elemento);
    }
  }, [elemento]);

  const handleAccept = () => {
    createRegistro(api, newValue.name, setNewValue);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange =
    (field: keyof ICatalogo) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewValue((prevValue) => ({ ...prevValue, [field]: e.target.value }));
    };

  return (
    <ARModal open={open} setOpen={setOpen}>
      <ARModalHeader>
        <ARTypography value={`${action} elemento`} />
      </ARModalHeader>
      <ARModalBody sx="flex flex-col space-y-4">
        <ARInput
          label="Nombre"
          value={newValue.name}
          onChange={handleChange("name")}
          sx="w-full"
        />
        {action?.toLowerCase() !== "agregar" && (
          <>
            <ARInput
              label="Fecha de Creación"
              type="date"
              defaultValue={new Date().toString()}
              value={newValue.created_at}
              sx="w-full"
              disabled
            />
            <ARInput
              label="Última Actualización"
              type="date"
              defaultValue={new Date().toString()}
              value={newValue.updated_at}
              sx="w-full"
              disabled
            />
          </>
        )}
      </ARModalBody>

      <ARModalActions>
        <button
          type="button"
          className="inline-flex ml-4 justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:w-auto"
          onClick={handleAccept}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={handleCancel}
        >
          Cancelar
        </button>
      </ARModalActions>
    </ARModal>
  );
};

export default ModalCatalogAction;
