import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Navbar from "../../components/Navbars/IndexNavbar";
import { ARCard } from "../../components/CustomComponents/ARCard/ARCard";
import { ARCardBody } from "../../components/CustomComponents/ARCard/ARCardBody";
import L, { DivIcon } from "leaflet";
import { getData } from "../../apis/Get";
import PrivateRoute from "../../layouts/PrivateRoute";
import { ARTypography } from "../CustomComponents/ARTypography";

interface IReport {
  id: number;
  title: string;
  description: string;
  report_type_id: number;
  status_id: number;
  priority_id: number;
  user_id: number;
  latitude: number;
  longitude: number;
  created_at: string;
  updated_at: string;
}

const exampleReports: IReport[] = [
  {
    id: 1,
    title: "Incidente en el Parque Central",
    description: "Un árbol cayó debido a la tormenta.",
    report_type_id: 1,
    status_id: 1,
    priority_id: 2,
    user_id: 3,
    latitude: 25.6715,
    longitude: -100.3129,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
  {
    id: 2,
    title: "Fugas de agua en Museo de Arte",
    description: "Se reportó una fuga en el techo del museo.",
    report_type_id: 2,
    status_id: 1,
    priority_id: 1,
    user_id: 4,
    latitude: 25.6725,
    longitude: -100.3029,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
  {
    id: 3,
    title: "Problema de iluminación en la plaza",
    description: "Las luces de la plaza no funcionan.",
    report_type_id: 3,
    status_id: 1,
    priority_id: 1,
    user_id: 5,
    latitude: 25.6735,
    longitude: -100.2929,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
  {
    id: 4,
    title: "Basura acumulada en la calle",
    description: "Se necesita limpieza en la calle principal.",
    report_type_id: 4,
    status_id: 2,
    priority_id: 2,
    user_id: 6,
    latitude: 25.6745,
    longitude: -100.2829,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
  {
    id: 5,
    title: "Fuga de gas en edificio",
    description: "Se reporta una fuga de gas en el edificio A.",
    report_type_id: 5,
    status_id: 1,
    priority_id: 1,
    user_id: 7,
    latitude: 25.6755,
    longitude: -100.2729,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
  {
    id: 6,
    title: "Mal estado de las aceras",
    description: "Las aceras están dañadas y presentan peligros.",
    report_type_id: 6,
    status_id: 1,
    priority_id: 2,
    user_id: 8,
    latitude: 25.6765,
    longitude: -100.2629,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
  {
    id: 7,
    title: "Robo en la tienda local",
    description: "Se reportó un robo en la tienda de la esquina.",
    report_type_id: 7,
    status_id: 1,
    priority_id: 1,
    user_id: 9,
    latitude: 25.6775,
    longitude: -100.2529,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
  {
    id: 8,
    title: "Problemas con el suministro de agua",
    description: "El suministro de agua ha estado interrumpido.",
    report_type_id: 8,
    status_id: 2,
    priority_id: 1,
    user_id: 10,
    latitude: 25.6785,
    longitude: -100.2429,
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
];

const exampleImages = [
  "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
  "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80",
];

const RenderCarousel = ({ images }: { images: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div className="relative w-full h-48 overflow-hidden">
      <div
        className="flex transition-transform duration-500"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Imagen ${index + 1}`}
            className="w-full h-full object-cover"
          />
        ))}
      </div>
      <button
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white p-2 rounded-full shadow"
        onClick={prevSlide}
      >
        &lt;
      </button>
      <button
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white p-2 rounded-full shadow"
        onClick={nextSlide}
      >
        &gt;
      </button>
    </div>
  );
};

export const ReportsMap = () => {
  const [selectedReport, setSelectedReport] = useState<IReport | null>(null);
  const [reports, setReports] = useState<IReport[]>(exampleReports);

  useEffect(() => {
    getData("/reports", setReports);
  }, []);

  const createIcon = (color: string, iconClass: string): DivIcon =>
    L.divIcon({
      html: `<span class="fa-stack small text-${color}"><i class="fas fa-circle fa-stack-2x"></i><i class="fas ${iconClass} fa-stack-1x fa-inverse"></i></span>`,
    });

  const renderMarkers = () =>
    reports.map((report) => {
      if (selectedReport && selectedReport.id !== report.id) {
        return null;
      }
      return (
        <Marker
          key={report.id}
          position={[report.latitude, report.longitude]}
          icon={createIcon("red-500", "fa-exclamation-triangle")}
        >
          <Popup>
            <div className="text-center">
              <h3 className="font-bold">{report.title}</h3>
              <button
                className="mt-2 text-red-600 hover:underline"
                onClick={() => setSelectedReport(report)}
              >
                Ver Detalles
              </button>
            </div>
          </Popup>
        </Marker>
      );
    });

  const renderReportList = () => (
    <div className="overflow-y-auto">
      {reports.length > 0 ? (
        reports.map((report) => (
          <div key={report.id} className="mb-2 border-b pb-2">
            <h4 className="font-semibold">{report.title}</h4>
            <button
              className="text-blue-600 hover:underline"
              onClick={() => setSelectedReport(report)}
            >
              Ver Detalles
            </button>
          </div>
        ))
      ) : (
        <ARTypography value={"Sin incidentes reportados"} />
      )}
    </div>
  );

  return (
    <PrivateRoute>
      <Navbar />
      <div
        className="flex w-full justify-center items-center"
        style={{ height: "90vh" }}
      >
        <div className="w-11/12 h-5/6">
          <ARCard className="h-full">
            <ARCardBody className="h-full">
              <div className="container mx-auto px-4 sm:px-8 py-4 flex flex-col h-full">
                <h2 className="text-2xl font-semibold text-center mb-4">
                  Mapa de Reportes
                </h2>
                <div className="z-0 flex-grow flex flex-col lg:flex-row">
                  <div className="lg:w-3/4 h-full">
                    <MapContainer
                      center={[25.6715, -100.3129]}
                      zoom={11}
                      style={{ height: "100%", width: "100%" }}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {renderMarkers()}
                    </MapContainer>
                  </div>

                  <div className="lg:w-1/4 p-4 bg-white rounded shadow-lg ml-0 lg:ml-4 mt-4 lg:mt-0">
                    <h3 className="text-lg font-semibold mb-2">
                      {selectedReport ? (
                        <button
                          className="text-m"
                          onClick={() => setSelectedReport(null)}
                        >
                          <i className="fas fa-arrow-left" />{" "}
                          {selectedReport.title}
                        </button>
                      ) : (
                        "Lista de Reportes"
                      )}
                    </h3>
                    {selectedReport ? (
                      <div>
                        <RenderCarousel images={exampleImages} />
                        <p>{selectedReport.description}</p>
                      </div>
                    ) : (
                      renderReportList()
                    )}
                  </div>
                </div>
              </div>
            </ARCardBody>
          </ARCard>
        </div>
      </div>
    </PrivateRoute>
  );
};
