import React from "react";
import { ARCard } from "../../components/CustomComponents/ARCard/ARCard";
import { ARCardBody } from "../../components/CustomComponents/ARCard/ARCardBody";
import Navbar from "../../components/Navbars/IndexNavbar";
import { Link } from "react-router-dom";
import { links } from "../../App";
import PrivateRoute from "../../layouts/PrivateRoute";

export interface ICatalog {
  catalogo: string;
  fnc: string;
  api: string;
}

const catalogs: ICatalog[] = [
  {
    catalogo: "Tipos de puntos de interés",
    fnc: "poiTypes",
    api: "/admin/pointtypes",
  },
  {
    catalogo: "Categoría de puntos de interés",
    fnc: "poiCategory",
    api: "/admin/pointcategories",
  },
];

const CatalogItem: React.FC<ICatalog> = ({ catalogo, fnc, api }) => {
  const linkTo = `${links.admin.catalogs}?catalog=${catalogo}&get=${api}`;

  return (
    <Link
      to={linkTo}
      className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent"
    >
      <div className="p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:border-gray-700 dark:hover:bg-gray-200">
        <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
          {catalogo}
        </h5>
      </div>
    </Link>
  );
};

export const PoiCatalogs: React.FC = () => {
  return (
    <PrivateRoute>
      <Navbar />
      <div
        className="flex w-full justify-center items-center"
        style={{ height: "90vh" }}
      >
        <div className="w-11/12 h-2/6">
          <ARCard>
            <ARCardBody>
              <h2 className="text-2xl font-semibold leading-tight">
                Catálogos de Punto de Interés
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 px-4 py-4">
                {catalogs.map((catalog) => (
                  <CatalogItem key={catalog.fnc} {...catalog} />
                ))}
              </div>
            </ARCardBody>
          </ARCard>
        </div>
      </div>
    </PrivateRoute>
  );
};
