import React, { useState } from "react";
import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";
import AdminMap from "../Maps/AdminMap";
import ModalAssignReport from "./ModalAssignReport";
import { IReport } from "../../views/Reports";

interface ModalViewReportMapProps {
  open: boolean;
  setOpen?: Function | undefined;
  action?: () => void;
  report?: IReport;
}

const ModalViewReportMap: React.FC<ModalViewReportMapProps> = ({
  open,
  setOpen,
  action,
  report,
}) => {
  const [openAssign, setOpenAssign] = useState<boolean>(false);

  const handleAccept = () => {
    if (action && setOpen) {
      action();
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      <ARModal open={open} setOpen={setOpen}>
        <ARModalBody>
          <AdminMap />
        </ARModalBody>
        <ARModalActions>
          {!report?.group && (
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 sm:ml-3 sm:w-auto ring-1 ring-inset ring-gray-300"
              onClick={() => setOpenAssign(true)}
              aria-label="Asignar Reporte"
            >
              Añadir reporte a grupo
            </button>
          )}

          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-200 sm:ml-3 sm:w-auto ring-1 ring-inset ring-gray-300"
            onClick={() => setOpenAssign(true)}
            aria-label="Asignar Reporte"
          >
            Asignar Reporte
          </button>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
            onClick={handleAccept}
            aria-label="Aceptar Reporte"
          >
            Aceptar
          </button>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
            onClick={() => setOpen && setOpen(false)}
            aria-label="Rechazar Reporte"
          >
            Rechazar
          </button>
        </ARModalActions>
      </ARModal>

      <ModalAssignReport open={openAssign} setOpen={setOpenAssign} />
    </React.Fragment>
  );
};

export default ModalViewReportMap;
