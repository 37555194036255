import React, { CSSProperties, ReactNode } from "react";

interface ARCardProps {
  children: ReactNode;
  className?: string; // Optional prop for additional styling
  style?: CSSProperties;
}

export const ARCard: React.FC<ARCardProps> = ({
  children,
  className = "",
  style,
}) => {
  return (
    <div
      className={`relative flex flex-col flex-grow shadow-lg rounded-lg bg-blueGray-100 border-4 ${className}`}
      style={style}
    >
      {children}
    </div>
  );
};
