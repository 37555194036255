import React, { useEffect, useState } from "react";
import axios from "axios";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Navbar from "../../components/Navbars/IndexNavbar";
import { ARCard } from "../../components/CustomComponents/ARCard/ARCard";
import { ARCardBody } from "../../components/CustomComponents/ARCard/ARCardBody";
import L, { DivIcon } from "leaflet";
import PoiDetail from "./PoiDetail";
import { getData } from "../../apis/Get";
import { ARCardActions } from "../CustomComponents/ARCard/ARCardActions";
import PrivateRoute from "../../layouts/PrivateRoute";
import { ARTypography } from "../CustomComponents/ARTypography";

interface Review {
  id: number;
  text: string;
  rating: number;
}

export interface IPointOfInterest {
  id: number;
  name: string;
  description: string;
  point_of_interest_type_id: number;
  category_id: number;
  user_id: number;
  latitude: number;
  longitude: number;
  created_at: string;
  updated_at: string;
  reviews: Review[];
}

const examplePointsOfInterest: IPointOfInterest[] = [
  {
    id: 1,
    name: "Parque Central",
    description: "Un gran parque para disfrutar al aire libre.",
    point_of_interest_type_id: 1,
    category_id: 1,
    user_id: 1,
    latitude: 25.6715,
    longitude: -100.3129,
    created_at: "2023-01-01T00:00:00Z",
    updated_at: "2023-01-01T00:00:00Z",
    reviews: [
      { id: 1, text: "Un lugar hermoso para pasear.", rating: 5 },
      { id: 2, text: "Excelente para hacer picnic.", rating: 4 },
    ],
  },
  {
    id: 2,
    name: "Museo de Arte",
    description: "Museo que alberga una colección de arte moderno.",
    point_of_interest_type_id: 2,
    category_id: 2,
    user_id: 2,
    latitude: 25.6725,
    longitude: -100.3029,
    created_at: "2023-01-01T00:00:00Z",
    updated_at: "2023-01-01T00:00:00Z",
    reviews: [
      {
        id: 1,
        text: "Una colección impresionante de arte moderno.",
        rating: 5,
      },
      { id: 2, text: "Muy educativo, un must en la ciudad.", rating: 3 },
    ],
  },
  {
    id: 3,
    name: "Catedral Metropolitana",
    description: "Imponente catedral con una arquitectura impresionante.",
    point_of_interest_type_id: 3,
    category_id: 3,
    user_id: 3,
    latitude: 25.6755,
    longitude: -100.3179,
    created_at: "2023-01-01T00:00:00Z",
    updated_at: "2023-01-01T00:00:00Z",
    reviews: [
      { id: 1, text: "Una obra maestra arquitectónica.", rating: 5 },
      { id: 2, text: "Impresionante por dentro y por fuera.", rating: 4 },
      { id: 3, text: "Un lugar de paz y reflexión.", rating: 4 },
    ],
  },
  {
    id: 4,
    name: "Plaza Principal",
    description: "El corazón de la ciudad, rodeada de tiendas y restaurantes.",
    point_of_interest_type_id: 4,
    category_id: 1,
    user_id: 4,
    latitude: 25.673,
    longitude: -100.305,
    created_at: "2023-01-01T00:00:00Z",
    updated_at: "2023-01-01T00:00:00Z",
    reviews: [
      { id: 1, text: "Ideal para pasar una tarde con amigos.", rating: 4 },
      { id: 2, text: "Ambiente muy agradable.", rating: 5 },
      { id: 3, text: "Demasiado concurrido a veces.", rating: 3 },
    ],
  },
  {
    id: 5,
    name: "Teatro Nacional",
    description: "Teatro histórico con una programación cultural variada.",
    point_of_interest_type_id: 5,
    category_id: 2,
    user_id: 5,
    latitude: 25.674,
    longitude: -100.31,
    created_at: "2023-01-01T00:00:00Z",
    updated_at: "2023-01-01T00:00:00Z",
    reviews: [
      { id: 1, text: "Las obras son siempre de alta calidad.", rating: 5 },
      {
        id: 2,
        text: "Un lugar mágico para disfrutar del arte escénico.",
        rating: 4,
      },
      { id: 3, text: "El sonido podría mejorar.", rating: 3 },
    ],
  },
  {
    id: 6,
    name: "Mercado Local",
    description: "Un vibrante mercado con productos frescos y comida típica.",
    point_of_interest_type_id: 6,
    category_id: 1,
    user_id: 6,
    latitude: 25.67,
    longitude: -100.308,
    created_at: "2023-01-01T00:00:00Z",
    updated_at: "2023-01-01T00:00:00Z",
    reviews: [
      { id: 1, text: "Excelente lugar para probar comida local.", rating: 5 },
      {
        id: 2,
        text: "Los productos son frescos y de buena calidad.",
        rating: 4,
      },
      { id: 3, text: "Puede estar muy lleno los fines de semana.", rating: 3 },
    ],
  },
];

export const PoiReviews = () => {
  const [pointsOfInterest, setPointsOfInterest] = useState<IPointOfInterest[]>(
    examplePointsOfInterest
  );
  const [selectedPOI, setSelectedPOI] = useState<IPointOfInterest | null>(null);

  useEffect(() => {
    // getData("/pointofinterest", setPointsOfInterest);
  }, []);

  const createIcon = (color: string, iconClass: string): DivIcon => {
    return L.divIcon({
      html: `<span class="fa-stack small text-${color}"><i class="fas fa-circle fa-stack-2x"></i><i class="fas ${iconClass} fa-stack-1x fa-inverse"></i></span>`,
    });
  };

  const calificacionEstrellas = (reviews: Review[]): string => {
    if (reviews.length === 0) return "";
    const promedio = Math.floor(
      reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length
    );
    return "⭐".repeat(promedio);
  };

  return (
    <PrivateRoute>
      <Navbar />
      {!selectedPOI ? (
        <div className="flex justify-center items-center w-full min-h-screen px-4">
          <div className="w-full max-w-4xl">
            <ARCard className="h-full">
              <ARCardBody>
                <div className="container mx-auto px-4 sm:px-8 py-4 flex flex-col h-full">
                  <h2 className="text-2xl font-semibold text-center mb-4">
                    Puntos de Interés
                  </h2>

                  <div className="z-0 flex-grow flex flex-col lg:flex-row">
                    <div className="lg:w-3/4 h-96 lg:h-[600px]">
                      <MapContainer
                        center={[25.6715, -100.3129]}
                        zoom={pointsOfInterest.length > 0 ? 14 : 11}
                        style={{ height: "100%", width: "100%" }}
                      >
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {pointsOfInterest.map((poi) => (
                          <Marker
                            key={poi.id}
                            position={[poi.latitude, poi.longitude]}
                            icon={createIcon("blue-500", "fa-map-marker-alt")}
                          >
                            <Popup>
                              <div className="text-center">
                                <h3 className="font-bold">{poi.name}</h3>
                                <button
                                  className="mt-2 text-blue-600 hover:underline"
                                  onClick={() => setSelectedPOI(poi)}
                                >
                                  Ver Reseñas
                                </button>
                              </div>
                            </Popup>
                          </Marker>
                        ))}
                      </MapContainer>
                    </div>

                    <div className="lg:w-1/4 p-4 bg-white rounded shadow-lg ml-0 lg:ml-4">
                      <h3 className="text-lg font-semibold">Reseñas</h3>
                      <ul className="divide-y divide-gray-200">
                        {pointsOfInterest.length > 0 ? (
                          pointsOfInterest.map((poi) => (
                            <li key={poi.id} className="py-2">
                              <button
                                className="text-blue-600 hover:underline"
                                onClick={() => setSelectedPOI(poi)}
                              >
                                {poi.name}
                              </button>{" "}
                              {calificacionEstrellas(poi.reviews)}
                              <p className="text-sm text-gray-500">
                                Reseñas: {poi.reviews.length}
                              </p>
                            </li>
                          ))
                        ) : (
                          <ARTypography value={"Reseñas no encontradas"} />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </ARCardBody>
            </ARCard>
          </div>
        </div>
      ) : (
        <div className="flex justify-center w-full min-h-screen px-4">
          <ARCard className="flex justify-center rounded shadow-lg">
            <ARCardActions>
              <button
                className="text-3xl"
                onClick={() => {
                  setSelectedPOI(null);
                }}
              >
                <i className="fas fa-arrow-left"></i>
              </button>
            </ARCardActions>
            <PoiDetail poi={selectedPOI} />
          </ARCard>
        </div>
      )}
    </PrivateRoute>
  );
};
