import Image from "../assets/img/bache.jpg";
import { ARTypography } from "../components/CustomComponents/ARTypography";
import Footer from "../components/Footers/Footer";
import Navbar from "../components/Navbars/AuthNavbar";
import { textos } from "./Landing";

export default function Index() {
  return (
    <>
      <Navbar transparent />
      <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-85">
        <div
          className="absolute top-0 w-full h-full bg-center bg-cover"
          style={{
            backgroundImage: `url(${Image})`,
          }}
        >
          <span
            id="blackOverlay"
            className="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div className="container relative mx-auto">
          <div className="items-center flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
              <div className="pr-12">
                <h1 className="text-white font-semibold text-5xl">
                  ¡Bienvenido!
                </h1>
                <p className="mt-4 text-lg text-blueGray-200 text-justify">
                  {textos.saludo}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                <img
                  alt="..."
                  src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80"
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-lightBlue-500 fill-current"
                    ></polygon>
                  </svg>
                  <ARTypography
                    sx="font-bold text-white"
                    value={"¿Quiénes Somos?"}
                  />
                  <ARTypography
                    sx="font-light mt-2 text-white"
                    value={textos.quienesSomos}
                  />
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-sitemap"></i>
                      </div>
                      <ARTypography sx="font-semibold" value={"Obten logros"} />
                      <ARTypography
                        sx="font-light mt-2 text-blueGray-500"
                        value={"Al validar reportes oportunos."}
                      />
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-drafting-compass"></i>
                      </div>
                      <ARTypography
                        sx="font-semibold"
                        value={"Revisión Diaria"}
                      />
                      <ARTypography
                        sx="font-light mt-2 text-blueGray-500"
                        value={"Para lograr tener una estadistica actualizada."}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-newspaper"></i>
                      </div>
                      <ARTypography
                        sx="font-semibold"
                        value={"Datos Verificados"}
                      />
                      <ARTypography
                        sx="font-light mt-2 text-blueGray-500"
                        value={
                          "Se validan todos los reportes para logar que sean lo mas certeros posibles"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>

      <section className="py-20 bg-blueGray-600 overflow-hidden">
        <div className="container mx-auto px-4 my-20">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div className="md:pr-12">
                <h3 className="text-3xl text-white font-semibold">
                  Tipos de Reportes
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-white text-justify">
                  {textos.tiposDeReporte.mensaje}
                </p>
                <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i className="fas fa-lightbulb"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-white">
                          Alumbrado Público: Reporta luminarias apagadas o en
                          mal estado.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i className="fas fa-paw"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-white">
                          Mascotas Perdidas o Peligrosas: Ayuda a encontrar
                          mascotas extraviadas o reporta animales que puedan ser
                          un peligro.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i className="fas fa-traffic-light"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-white">
                          Vialidades: Informa sobre baches, señalización
                          deficiente o cualquier problema en las calles.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i className="fas fa-trash"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-white">
                          Basura y Limpieza: Denuncia acumulación de basura,
                          escombros o falta de limpieza en áreas públicas.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i className="fas fa-shield"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-white">
                          Seguridad: Reporta situaciones de riesgo o actos de
                          vandalismo en tu comunidad.
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                          <i className="fas fa-building"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-white">
                          Infraestructura Pública: Notifica sobre problemas en
                          parques, áreas recreativas o instalaciones públicas.
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul>
                <p className="mt-4 text-lg leading-relaxed text-white text-justify">
                  {textos.tiposDeReporte.mensajeExtra}
                </p>
              </div>
            </div>
            <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-lg"
                src={Image}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-blueGray-200 relative pt-32">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
            <div className="container mx-auto px-4">
              <div className="flex flex-wrap text-center justify-center">
                <h2 className="text-4xl font-semibold text-blueGray-700">
                  ¡Usa Nuestra App!
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-justify">
                  {textos.invitacionApp}
                </p>
              </div>
              <div className="flex flex-wrap mt-12 justify-center">
                <div className="w-full lg:w-3/12 px-4 text-center">
                  <button className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full inline-flex items-center justify-center">
                    <i className="fab fa-google-play text-xl"></i>
                  </button>
                  <h6 className="text-xl mt-5 font-semibold text-blueGray-500">
                    PlayStore
                  </h6>
                </div>
                <div className="w-full lg:w-3/12 px-4 text-center">
                  <button className="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full inline-flex items-center justify-center">
                    <i className="fab fa-app-store text-xl"></i>
                  </button>
                  <h5 className="text-xl mt-5 font-semibold text-blueGray-500">
                    AppStore
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
