/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "../Sidebar/Sidebar";
import { CardProfile } from "../Cards/CardProfile";

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <>
      <Sidebar open={navbarOpen} setOpen={setNavbarOpen} />
      <nav className="z-50 inset-x-0 top-0 bg-gray-800 text-white flex flex-col lg:flex-row w-full h-20 items-center">
        <div className="container flex items-center px-4">
          <button
            className="p-4 text-2xl"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i className="fas fa-bars" />
          </button>
          <Link
            to="/"
            className="text-white text-xl font-bold leading-relaxed py-2 whitespace-nowrap uppercase"
          >
            App Reporte
          </Link>
        </div>

        <div className="flex flex-grow items-center lg:ml-auto lg:bg-opacity-0 lg:shadow-none">
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center mb-2 lg:mb-0">
              <CardProfile
                userInfo={{
                  id: "1",
                  name: "Jonathan Bustos",
                  email: "jona.bustos@correo.com",
                  role_id: "Master Developer",
                }}
              />
            </li>
            {/* Add more nav items here if needed */}
          </ul>
        </div>
      </nav>
    </>
  );
}
