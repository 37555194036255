import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";

import { ARModalHeader } from "../CustomComponents/ARModal/ARModalHeader";
import { ARTypography } from "../CustomComponents/ARTypography";

export default function ModalAssignReport({ open, setOpen, action }: any) {
  return (
    <ARModal open={open} setOpen={setOpen}>
      <ARModalHeader>
        <ARTypography value={"Asignar Reporte"} />
      </ARModalHeader>
      <ARModalBody>
        <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
          <option>Seleccionar usuario</option>
          <option>Jona</option>
          <option>Diego</option>
          <option>Toño</option>
        </select>
      </ARModalBody>
      <ARModalActions>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-green px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => {}}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancelar
        </button>
      </ARModalActions>
    </ARModal>
  );
}
