import { ARModal } from "../CustomComponents/ARModal/ARModal";
import { ARModalBody } from "../CustomComponents/ARModal/ARModalBody";
import { ARModalActions } from "../CustomComponents/ARModal/ARModalActions";
import { ARModalHeader } from "../CustomComponents/ARModal/ARModalHeader";
import { ARTypography } from "../CustomComponents/ARTypography";
import { FC, useState, useEffect } from "react";
import { IUsuario } from "../../views/admin/Users";
import { ARInput } from "../CustomComponents/ARInput";
import { ARSelect } from "../CustomComponents/ARSelect";
import { createUsuario } from "../../apis/Users";

interface ModalUserActionProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  user?: IUsuario;
  action?: string;
}

const ModalUserAction: FC<ModalUserActionProps> = ({
  open,
  setOpen,
  user,
  action,
}) => {
  const [newValue, setNewValue] = useState<IUsuario>({
    id: 0,
    name: "",
    last_name: "",
    email: "",
    role_id: "",
    username: "",
    created_at: "",
    updated_at: "",
  });

  useEffect(() => {
    if (user) {
      setNewValue(user);
    }
  }, [user]);

  const handleAccept = () => {
    if (!newValue.name || !newValue.email) {
      alert("Please fill in all required fields.");
      return;
    }
    createUsuario("/user", newValue);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleChange =
    (field: keyof IUsuario) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      console.log(e.target.value);

      setNewValue((prevValue) => ({ ...prevValue, [field]: e.target.value }));
    };

  return (
    <ARModal open={open} setOpen={setOpen}>
      <ARModalHeader>
        <ARTypography value={`${action} usuario`} />
      </ARModalHeader>
      <ARModalBody sx="grid grid-cols-2 justify-center justify-items-center">
        <ARInput
          label="ID"
          value={newValue.id}
          sx="w-full"
          size="lg"
          disabled
        />
        <ARInput
          label="Username"
          value={newValue.username}
          onChange={handleChange("username")}
          sx="w-full"
          size="lg"
          disabled
        />
        <ARInput
          label="Nombre"
          value={newValue.name}
          onChange={handleChange("name")}
          sx="w-full"
          size="lg"
          disabled={action?.toLowerCase() === "eliminar"}
        />
        <ARInput
          label="Apellido(s)"
          value={newValue.last_name}
          onChange={handleChange("last_name")}
          sx="w-full"
          size="lg"
          disabled={action?.toLowerCase() === "eliminar"}
        />
        <ARInput
          label="Correo electrónico"
          value={newValue.email}
          onChange={handleChange("email")}
          sx="w-full"
          size="lg"
          disabled
        />
        <ARInput
          label="Teléfono"
          value={newValue.phone}
          onChange={handleChange("phone")}
          sx="w-full"
          size="lg"
          disabled={action?.toLowerCase() === "eliminar"}
        />
        <ARInput
          label="Verificar Teléfono"
          value={newValue.phone_verify}
          onChange={handleChange("phone_verify")}
          sx="w-full"
          size="lg"
          disabled={action?.toLowerCase() === "eliminar"}
        />
        <ARSelect
          label="Rol"
          options={[
            { id: 1, value: "App dev" },
            { id: 2, value: "Sponsor" },
            { id: 3, value: "Backend dev" },
          ]}
          value={newValue.role_id}
          onChange={(e) => handleChange("role_id")(e)}
          sx="w-full"
          size="lg"
          disabled={action?.toLowerCase() === "eliminar"}
        />
      </ARModalBody>

      <ARModalActions>
        <button
          type="button"
          className="inline-flex ml-4 justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:w-auto"
          onClick={handleAccept}
        >
          Aceptar
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={handleCancel}
        >
          Cancelar
        </button>
      </ARModalActions>
    </ARModal>
  );
};

export default ModalUserAction;
