import React from "react";

interface ARModalProps {
  open: boolean;
  setOpen?: Function | undefined;
  children?: React.ReactNode;
}

export const ARModal: React.FC<ARModalProps> = ({ open, children }) => {
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {open && (
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden={!open}
        >
          <div className="fixed inset-0 z-10 flex items-center justify-center overflow-y-auto">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
              {children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
