import L, { DivIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { getData } from "../../apis/Get";
import CardStats from "../../components/Cards/CardStats";
import Navbar from "../../components/Navbars/IndexNavbar";
import PrivateRoute from "../../layouts/PrivateRoute";
import { ARTypography } from "../../components/CustomComponents/ARTypography";

interface IMarkerData {
  id: string;
  title: string;
  description: string;
  report_type_id: string;
  status_id: string;
  priority_id: string;
  user_id: string;
  latitude: number;
  longitude: number;
  created_at: string;
  updated_at: string;
}

//ELIMINAR AL APROVAR
const exampleMarkersData: IMarkerData[] = [
  {
    id: "1",
    title: "Accidente en la intersección",
    description: "Un accidente ocurrió a las 3 PM. Vehículos involucrados: 2.",
    report_type_id: "1",
    status_id: "2",
    priority_id: "1",
    user_id: "101",
    latitude: 25.67151333465,
    longitude: -100.3128631307,
    created_at: "2024-10-01T14:00:00Z",
    updated_at: "2024-10-01T15:00:00Z",
  },
  {
    id: "2",
    title: "Luces de semáforo fuera de servicio",
    description: "Las luces de semáforo están apagadas desde ayer.",
    report_type_id: "2",
    status_id: "1",
    priority_id: "2",
    user_id: "102",
    latitude: 25.67251322465,
    longitude: -100.3028221307,
    created_at: "2024-10-01T10:30:00Z",
    updated_at: "2024-10-01T12:00:00Z",
  },
  {
    id: "3",
    title: "Accidente múltiple",
    description:
      "Un accidente con múltiples vehículos fue reportado en la carretera.",
    report_type_id: "1",
    status_id: "2",
    priority_id: "1",
    user_id: "103",
    latitude: 25.68051322465,
    longitude: -100.3028221307,
    created_at: "2024-10-01T11:00:00Z",
    updated_at: "2024-10-01T12:15:00Z",
  },
  {
    id: "4",
    title: "Problemas de tráfico",
    description: "Se reportan problemas de tráfico en la vía principal.",
    report_type_id: "3",
    status_id: "1",
    priority_id: "3",
    user_id: "104",
    latitude: 25.68001322465,
    longitude: -100.3008221307,
    created_at: "2024-10-01T09:00:00Z",
    updated_at: "2024-10-01T10:00:00Z",
  },
  {
    id: "5",
    title: "Desperfecto en el puente",
    description:
      "Se ha reportado un desperfecto en el puente de la avenida principal.",
    report_type_id: "4",
    status_id: "2",
    priority_id: "2",
    user_id: "105",
    latitude: 25.67801322465,
    longitude: -100.2988221307,
    created_at: "2024-10-01T12:00:00Z",
    updated_at: "2024-10-01T13:00:00Z",
  },
];

const createIcon = (color: string, iconClass: string): DivIcon =>
  L.divIcon({
    html: `<span class="fa-stack small text-${color}"><i class="fas fa-circle fa-stack-2x"></i><i class="fas ${iconClass} fa-stack-1x fa-inverse"></i></span>`,
  });

const mapIcons = {
  light: createIcon("lightBlue-500", "fa-traffic-light"),
  crash: createIcon("red-500", "fa-car-crash"),
  road: createIcon("emerald-500", "fa-road"),
  traffic: createIcon("orange-500", "fa-chart-pie"),
};

const Maps = () => {
  const [markersData, setMarkersData] =
    useState<IMarkerData[]>(exampleMarkersData);

  const [visibleIcons, setVisibleIcons] = useState<Record<string, boolean>>({
    light: true,
    crash: true,
    road: true,
    traffic: true,
  });

  useEffect(() => {
    getData("/reports", setMarkersData);
  }, []);

  const toggleIconVisibility = (icon: keyof typeof visibleIcons) => {
    setVisibleIcons((prev) => ({ ...prev, [icon]: !prev[icon] }));
  };

  return (
    <PrivateRoute>
      <Navbar />
      <div
        className="flex w-full items-center justify-center"
        style={{ height: "90vh" }}
      >
        {
          <div className="w-1/4 p-4 overflow-y-auto ">
            {markersData.length > 0 ? (
              markersData.map((marker) => (
                <>
                  <CardStats
                    key={marker.id}
                    statSubtitle={marker.title}
                    statTitle={marker.id}
                    statArrow="up"
                    statPercent="0"
                    statPercentColor="text-emerald-500"
                    statIconName={`fas ${
                      marker.title.toLocaleLowerCase().includes("luces")
                        ? "lightBlue-500 fa-traffic-light"
                        : marker.title.toLocaleLowerCase().includes("accidente")
                        ? "red-500 fa-car-crash"
                        : marker.title.toLocaleLowerCase().includes("trafico")
                        ? "orange-500 fa-chart-pie"
                        : "emerald-500 fa-road"
                    }`}
                    statIconColor={`${
                      marker.title.toLocaleLowerCase().includes("luces")
                        ? "bg-lightBlue-500"
                        : marker.title.toLocaleLowerCase().includes("accidente")
                        ? "bg-red-500"
                        : marker.title.toLocaleLowerCase().includes("trafico")
                        ? "bg-orange-500"
                        : "bg-emerald-500"
                    }`}
                    onClick={() =>
                      toggleIconVisibility(
                        marker.title.toLocaleLowerCase().includes("luces")
                          ? "light"
                          : marker.title
                              .toLocaleLowerCase()
                              .includes("accidente")
                          ? "crash"
                          : marker.title.toLocaleLowerCase().includes("trafico")
                          ? "traffic"
                          : "road"
                      )
                    }
                  />
                  <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
                </>
              ))
            ) : (
              <ARTypography value={"Sin incidentes reportados"} />
            )}
          </div>
        }
        <div className="w-8/12 h-3/4 flex-center">
          <MapContainer
            style={{ height: "100%", zIndex: "0" }}
            center={[25.6715, -100.3129]}
            zoom={11}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markersData.map((marker) =>
              visibleIcons[
                marker.title.toLocaleLowerCase().includes("luces")
                  ? "light"
                  : marker.title.toLocaleLowerCase().includes("accidente")
                  ? "crash"
                  : marker.title.toLocaleLowerCase().includes("trafico")
                  ? "traffic"
                  : "road"
              ] ? (
                <Marker
                  key={marker.id}
                  position={[marker.latitude, marker.longitude]}
                  icon={
                    marker.title.toLocaleLowerCase().includes("luces")
                      ? mapIcons["light"]
                      : marker.title.toLocaleLowerCase().includes("accidente")
                      ? mapIcons["crash"]
                      : marker.title.toLocaleLowerCase().includes("trafico")
                      ? mapIcons["traffic"]
                      : mapIcons["road"]
                  }
                >
                  <Popup>
                    <div>
                      <strong>{marker.title}</strong>
                      <br />
                      {marker.description}
                    </div>
                  </Popup>
                </Marker>
              ) : null
            )}
          </MapContainer>
        </div>
      </div>
    </PrivateRoute>
  );
};

export default Maps;
