import axios from "axios";

export const getData = async (end: string, set: Function) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      process.env.REACT_APP_APPLICATION_BACK + end,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    set(response.data);
  } catch (error) {
    console.error("Error fetching markers data:", error);
  }
};
