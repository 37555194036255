import axios from "axios";

export const createRegistro = async (
  end: string,
  name: string,
  set: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "post",
      url: process.env.REACT_APP_APPLICATION_BACK + end,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: name,
      },
    });

    set(response.data);
  } catch (error) {
    alert(`Error al crear el registro: ${error}`);
  }
};

export const updateRegistro = async (
  end: string,
  id: number,
  name: string,
  set: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "put",
      url: process.env.REACT_APP_APPLICATION_BACK + end,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id,
        name: name,
      },
    });
    set(response.data);
  } catch (error) {
    alert(`Error al actualizar el registro: ${error}`);
  }
};

export const deleteRegistro = async (
  end: string,
  id: number,
  set: Function
) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios({
      method: "delete",
      url: process.env.REACT_APP_APPLICATION_BACK + end,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id,
      },
    });
    set(response.data);
  } catch (error) {
    alert(`Error al eliminar el registro: ${error}`);
  }
};
