import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

function AdminMap() {
  const blueIcon = L.divIcon({
    html:
      '<span class="fa-stack small text-lightBlue-500">' +
      '<i class="fas fa-circle fa-stack-2x"></i>' +
      '<i class="fas fa-traffic-light fa-stack-1x fa-inverse"></i>' +
      "</span>",
  });
  const redIcon = L.divIcon({
    html:
      '<span class="fa-stack small text-red-500">' +
      '<i class="fas fa-circle fa-stack-2x"></i>' +
      '<i class="fas fa-car-crash fa-stack-1x fa-inverse"></i>' +
      "</span>",
  });
  const greenIcon = L.divIcon({
    html:
      '<span class="fa-stack small text-emerald-500">' +
      '<i class="fas fa-circle fa-stack-2x"></i>' +
      '<i class="fas fa-road fa-stack-1x fa-inverse"></i>' +
      "</span>",
  });
  return (
    <div className="relative w-full rounded h-600-px">
      <MapContainer
        style={{ height: "600px", zIndex: "0" }}
        center={[25.67151333465, -100.3128631307]}
        zoom={14}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[25.67151333465, -100.3128631307]} icon={blueIcon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
        <Marker position={[25.67251322465, -100.3028221307]} icon={greenIcon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
        <Marker position={[25.68051322465, -100.3028221307]} icon={redIcon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
        <Marker position={[25.68001322465, -100.3008221307]} icon={redIcon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default AdminMap;
